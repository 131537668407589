import {useEffect, useState} from "react";
import {Input, Row, Col, Button, Space, Form, Tabs} from 'antd';
import axios from "axios";
import {DownloadOutlined, PlusOutlined, MinusCircleOutlined, SearchOutlined} from "@ant-design/icons";
const {Search} = Input;

function saveFile(text) {
    var data = text;
    var a = document.getElementById("linkForSavingFiles");
    if (a) {
        var file = new Blob([data], {
            type: 'plain/text'
        });
        a.setAttribute('href', URL.createObjectURL(file));
        a.setAttribute('download', 'competitors.txt');
        a.click();
    }
}
function parseHTML(markup) {
    if (markup.toLowerCase().trim().indexOf('<!doctype') === 0) {
        var doc = document.implementation.createHTMLDocument("");
        console.log(doc);
        doc.documentElement.innerHTML = markup;
        return doc;
    } else if ('content' in document.createElement('template')) {
       // Template tag exists!
       var el = document.createElement('template');
       el.innerHTML = markup;
       return el.content;
    } else {
       // Template tag doesn't exist!
       var docfrag = document.createDocumentFragment();
       var el = document.createElement('body');
       el.innerHTML = markup;
       for (var i = 0; 0 < el.childNodes.length;) {
           docfrag.appendChild(el.childNodes[i]);
       }
       return docfrag;
    }
}
const YTResponseToVideosDict = (YTresponse) => {
    const scriptContent = parseHTML(YTresponse).body.getElementsByTagName('script')[13].innerHTML;
    const jsonContent = JSON.parse(
        scriptContent
            .replace('var ytInitialData = ', '')
            .replace(';', '')
    );
    return (jsonContent.contents.twoColumnBrowseResultsRenderer.tabs
        .find((el)=>el.tabRenderer.title==='Видео')
        .tabRenderer.content.sectionListRenderer.contents[0]
        .itemSectionRenderer.contents[0].gridRenderer.items
        .filter((el)=>el.gridVideoRenderer)
        .map(({gridVideoRenderer}) => ({
            videoId: gridVideoRenderer?.videoId,
            title: `${gridVideoRenderer?.title.runs[0].text}`,
            description: 'Будет позднее...'
        })));
}

const parseChannels = async (channels_url) => {
    if (!channels_url.length) return []
    let channels = [];
    await axios.all(channels_url.map((url) =>
        axios.get(url.replace('https://www.youtube.com', 'youtube.com')+'/videos', {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
            }
        })))
            .then((data)=> {
                channels = data.map((channel_data, index)=>({
                    url: channels_url[index],
                    videos: YTResponseToVideosDict(channel_data.data)
                }))
            })
    // const param = 'channels_urls='+channels_url.join('&channels_urls=');
    // await axios.post(`api/parse_channels?${param}`)
    //     .then(({data}) => {
    //         console.log(data)
    //         channels = data;
    //     })
    return channels;
}

export const CompetitorsBlock = () => {
    const [search, setSearch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [channelsInfo, setChannelsInfo] = useState([]);
    const tabsContent = channelsInfo.map((channel, channel_id)=>{
        const splittedUrl = channel.url.split('/');
        const channelName = splittedUrl[splittedUrl.length-1]
        return {
            label: channelName,
            key: channel_id,
            children:
                <Tabs
                    defaultActiveKey="1"
                    tabPosition={'left'}
                    className={'tabs_videos'}
                    items={
                        channel?.videos.map(
                            (video, video_id) => ({
                                label: video.title,
                                key: channel_id+'_'+video_id,
                                children: <p className={'scrollable'}>{video.description}</p>
                            })
                        )
                    }
                />
        }
    })

    useEffect(() => {
        async function getChannels() {
            setChannelsInfo(await parseChannels(search));
            setLoading(false);
        };
        if (search) {
            setLoading(true);
            getChannels()
        }
    }, [search]);
    useEffect(() => {
        console.log(channelsInfo)
    }, [channelsInfo]);
    return (
        <div className={'competitors block'}>
            <h2 className={'block__header'}>Парсинг конкурентов</h2>
            <p className={'block__description'}>
                Сервис предоставит вам список тегов, которые используются у видео, и ключевых слов
                из описаний каналов, ссылки на которые вы укажете в форме ниже (можно указать до 10 ссылок за раз).
                Теги предоставляются в том же порядке, в котором они изначально указаны у видео или канала, если
                вы хотели бы отсортировать их, например, по алфавиту, воспользуйтесь нашим онлайн сервисом обработки
                текста.
            </p>
            <div className={'block__input-block'}>
                <Form name="dynamic_form_nest_item" onFinish={({urls})=>setSearch(urls.map((el)=>el.url))} autoComplete="off">
                  <Form.List name="urls">
                    {(test, { add, remove }) => (
                      <>
                          {test.length === 0 &&
                              <Space key={0} style={{display: 'flex', marginBottom: 8, width: '100%'}} align="baseline">
                                  <Form.Item
                                      name={[0, 'url']}
                                      rules={[{required: true, message: 'Укажите ссылку на канал'}]}
                                  >
                                       <Search
                                            placeholder="Ссылка на канал"
                                            enterButton=<MinusCircleOutlined/>
                                            onSearch={()=>remove(0)}
                                        />
                                  </Form.Item>
                              </Space>
                          }
                        {test.map(({ key, name, ...restField }) => (
                          <Space key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="baseline">
                            <Form.Item
                              {...restField}
                              name={[name, 'url']}
                              rules={[{ required: true, message: 'Missing first name' }]}
                            >
                                       <Search
                                            placeholder="Ссылка на канал"
                                            enterButton=<MinusCircleOutlined/>
                                            onSearch={()=>remove(name)}
                                        />
                            </Form.Item>
                          </Space>
                        ))}
                              <Form.Item>
                                  <Row style={{width: '100%', margin: '0 auto', justifyContent: "center"}}>
                                      {test.length < 10 &&
                                          <Col span={10} style={{margin: "0 10px"}}>
                                              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                                  Добавить
                                              </Button>
                                          </Col>
                                      }
                                      <Col span={10}>
                                        <Button type="primary" htmlType="submit" style={{width: '100%'}} icon={<SearchOutlined />}>
                                          Спарсить
                                        </Button>
                                      </Col>
                                  </Row>
                              </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form>
            </div>
            {channelsInfo.length > 0 && !loading &&
                <div style={{marginTop: '50px'}}>
                    <div className={'results__header'}>
                        <h3 className={'results__header-title'}>Результаты поиска</h3>
                        <Button className={'results__header-button'} shape="round" icon={<DownloadOutlined />} onClick={() => saveFile(channelsInfo)}>
                            Excel
                        </Button>
                        <a href='/' id='linkForSavingFiles' style={{'display': 'none'}}>.</a>
                    </div>
                    <hr style={{margin: '0 10% 25px calc(10% - 7px)'}}/>
                    <Tabs
                        className={'tabs_channels'}
                        defaultActiveKey="1"
                        tabPosition={'left'}
                        items={tabsContent}
                    />
                </div>
            }
        </div>


                // <div>
                //     <h3 className={'results__header'}>Результаты поиска</h3>
                //     <div className={'results'}>
                //         <Row>
                //             <Col span={8}>
                //                 <div className={'competitorsList list'}>
                //                     {searchSplitted.map((competitor) => (
                //                         <Button  type="dashed">
                //                             {competitor.replace('https://www.youtube.com/channel/', '')}
                //                         </Button>
                //                     ))}
                //                 </div>
                //             </Col>
                //             <Col span={16}>
                //                 <div className={'competitorsInfo list'}>
                //                     <Row>
                //                         <Col span={12}>
                //                             <h4>Название</h4>
                //                         </Col>
                //                         <Col span={12}>
                //                             <h4>Описание</h4>
                //                         </Col>
                //                     </Row>
                //                     {channelsInfo.map((video, index) => (
                //                         <Row key={'competitorsInfo'+index}>
                //                             <Col span={12}>
                //                                 <p>{video.title}</p>
                //                             </Col>
                //                             <Col span={12}>
                //                                 <p>{video.description}</p>
                //                             </Col>
                //                         </Row>
                //                     ))}
                //                 </div>
                //             </Col>
                //         </Row>
                //     </div>
    );
}