import { DownloadOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import { Input, Row, Col, Button } from 'antd';
import axios from "axios";
const { Search } = Input;

function saveFile(text) {
    var data = text;
    var a = document.getElementById("linkForSavingFiles");
    if (a) {
        var file = new Blob([data], {
            type: 'plain/text'
        });
        a.setAttribute('href', URL.createObjectURL(file));
        a.setAttribute('download', 'tags.txt');
        a.click();
    }
}

const getRelatedVideoTags = async (tag) => {
    if (!tag.length) return []
    let response = [];
    await axios.post(`api/related_video_tags?tag_to_check=${tag}&refresh_token=1%2F%2F0culpiDlRukMCCgYIARAAGAwSNwF-L9Ir1gFaH4JhIPP25GOdeWGlLjEloJwWIxO0OgNgxQC7kTwJeDod2oPs_aty-hG4xLluxnE`)
        .then(({data}) => {
            response = data;
        })
    return response;
}

export const TagSelection = () => {
    const [search, setSearch] = useState('');
    const [tags, setTags] = useState([]);
    const [volume, setVolume] = useState([]);
    const [apiResponse, setApiResponse] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getTags() {
            const response = (await getRelatedVideoTags(search));
            setApiResponse(response.map((el)=>'Название: '+el.title+', Объем: '+el.search_volume).join('\n'));
            const [responseTags, responseVolume] = [response.map((el) => el.title), response.map((el) => el.search_volume)];
            setTags(responseTags);
            setVolume(responseVolume);
            setLoading(false);
        };
        if (search) {
            setLoading(true);
            getTags()
            // setTags([]);
            // setVolume([]);
            // function sleep (time) {
            //   return new Promise((resolve) => setTimeout(resolve, time));
            // }
            // sleep(1000).then(() => {
            //     setVolume([
            //         '151', '152', '153', '154', '155',
            //         '151', '152', '153', '154', '155',
            //         '151', '152', '153', '154'
            //     ])
            //     setTags([
            //     'тег 1', 'тег 2', 'тег 3', 'тег 4', 'тег 5', 'тег 3', 'тег 4', 'тег 5', 'тег 3', 'тег 4', 'тег 5', 'тег 3', 'тег 4', 'тег 5'
            //     ])
            //     setLoading(false);
            // });
        }
    }, [search]);

    return (
        <div className={'tagSelection block'}>
            <h2 className={'block__header'}>Подбор тегов по ключевому слову или фразе</h2>
            <p className={'block__description'}>Сервис подберет и предоставит вам готовый список тегов для Ютуба через запятую (и построчно), которые будут тематически близки заданному вами основному ключевому слову. Из полученного списка вам останется лишь отобрать то, что точно относится к вашему ролику и выбрать из них самые популярные (чем ближе к началу списка - тем популярнее).</p>
            <div className={'block__input-block'}>
                <Search
                    placeholder="Основное ключевое слово"
                    loading={loading}
                    enterButton="Собрать теги"
                    // onClick={() => setLoading(true)}
                    onSearch={(e)=>setSearch(e)}
                    onPressEnter = {(e) => setSearch(e.target.value)}
                />
            </div>
            {tags.length>0 &&
                <div style={{marginTop: '50px'}}>
                    <div className={'results__header'}>
                        <h3 className={'results__header-title'}>Результаты поиска</h3>
                        <Button className={'results__header-button'} shape="round" icon={<DownloadOutlined />} onClick={() => saveFile(apiResponse)}>
                            Excel
                        </Button>
                        <a href='/' id='linkForSavingFiles' style={{'display': 'none'}}>.</a>
                    </div>
                    <hr style={{margin: '0 10% 0 calc(10% - 7px)'}}/>
                    <div className={'results scrollable'} style={{overflowY: 'auto', height: '240px'}}>
                        <Row style={{height: '100%', margin: '0 15%'}}>
                            <Col span={12}>
                                <div className={'tags'}>
                                    {tags.map((tag) => (
                                        <p>{tag}</p>
                                    ))}
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={'volume'}>
                                    {volume.map((tagVolume) => (
                                        <p>{tagVolume}</p>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            </div>
    )
}