import {useEffect, useState} from "react";
import axios from "axios";
import { Input, Button, Tabs } from "antd";
import {DownloadOutlined} from "@ant-design/icons";
const {Search} = Input;

const searchSuggestion = async (query) => {
    let suggestions = [];
    await axios.post(`api/search_suggestions?query=${query}`)
        .then(({data}) => {
            suggestions = data.map((e)=>e.text);
        })
    return suggestions;
}

function saveFile(text) {
    var data = text;
        // let el = document.createElement('a');
    var a = document.getElementById("linkForSavingFiles");
    console.log('saving File')
    if (a) {
        var file = new Blob([data], {
            type: 'plain/text'
        });
        a.setAttribute('href', URL.createObjectURL(file));
        a.setAttribute('download', 'hints.txt');
        a.click();
    }
}

export const HintParser = () => {
    const [hints, setHints] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('');
    useEffect(() => {
        async function getHints() {
            let suggestions = await searchSuggestion(search);
            setHints(suggestions)
            setLoading(false)
        };
        if (search) {
            setLoading(true)
            getHints()
        }
    }, [search]);


    return (
        <div className={'hintParser block'}>
            <h2 className={'block__header'}>Парсинг ключевых слов из подсказок</h2>
            <p className={'block__description'}>
                Когда на сайте Youtube.com вы вводите что-то в поисковую строку, там моментально начинают отображаться подсказки к только что введенному вами тексту - это популярные ключевые слова, которые часто вводят в поиск все пользователи Ютуба. Наш сервис соберет для вас все варианты этих подсказок и предоставит их общим списком в 2 форматах: построчно и через запятую. Укажите в поле ниже ключевое слово или фразу (до 3 слов через пробел).
            </p>
            <div className={'block__input-block'}>
                <Search
                    placeholder="Основное ключевое слово"
                    loading={loading}
                    enterButton="Теги из подсказок"
                    onSearch={(e)=>setSearch(e)}
                    onPressEnter = {(e) => setSearch(e.target.value)}
                />
            </div>
            {hints.length>=1 &&
                <div style={{marginTop: '50px'}}>
                    <div className={'results__header'}>
                        <h3 className={'results__header-title'}>Результаты поиска</h3>
                        <Button className={'results__header-button'} shape="round" icon={<DownloadOutlined />} onClick={() => saveFile(hints.join('\n'))}>
                            Excel
                        </Button>
                        <a href='/' id='linkForSavingFiles' style={{'display': 'none'}}>.</a>
                    </div>
                    {/*<hr style={{margin: '0 10% 0 calc(10% - 7px)'}}/>*/}
                    <div className={'results'} style={{maxHight: '240px'}}>
                        <Tabs
                          defaultActiveKey="1"
                          style={{height: '100%', maxHeight: 'inherit'}}
                          items={[
                              {
                                label: 'Построчно',
                                key: '1',
                                children:
                                    <div className={'hintListString scrollable'} style={{overflowY: 'auto', height: '100%', maxHeight: 'inherit'}}>
                                        {hints.map((hint, index) => (
                                            <p key={hint + index}>{hint}</p>
                                        ))}
                                    </div>,
                              },
                              {
                                label: `Через запятую`,
                                key: '2',
                                children:
                                    <div className={'hintListSemicolon list'}>
                                        <p>{hints.join(', ')}</p>
                                    </div>,
                              },
                              {
                                label: `Хештеги`,
                                key: '3',
                                children:
                                    <div className={'hintListSemicolon list'}>
                                        <p>{hints.map((el)=>`#${el.replaceAll(' ', '')}`).join(' ')}</p>
                                    </div>,
                              },
                          ]}
                        />
                    </div>
                </div>
            }
        </div>
    )
}