import { Button } from 'antd'
import { Logo } from "./Navigation/Logo";

const routes = {
    '/blog': "Блог",
    '/contacts': "Контакты",
    '/social': "Соц. сети"
}

export const Navigation = () => {
    return (
        <div className={'navigation'}>
            <Logo />
            <div className={'routes'}>
                {Object.entries(routes).map((item)=>(
                    <Button type="link" className={'route'} key={item[0]}>
                        <a href={item[0]}>{item[1]}</a>
                    </Button>
                ))}
            </div>
        </div>
    )
}