import 'antd/dist/antd.min.css';
import './App.css';
import { Navigation } from "./Components/Navigation";
import { TagSelection } from './Components/TagSelection';
import { CompetitorsBlock } from "./Components/CompetitorsBlock";
import { HintParser } from "./Components/HintParser";

function App() {
  return (
    <div className="App">
      <Navigation />
      <TagSelection />
      <CompetitorsBlock />
      <HintParser />
    </div>
  );
}

export default App;
